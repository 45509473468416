import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SobreNos from "../components/sobre-nos"
import AutorizadasList from "../components/autorizadas-list"
import { Link } from "gatsby"

const Autorizadas = () => {
  return (
    <Layout showHero>
      <SEO title="Autorizadas Pelas Maiores Marcas do Brasil | Cedilar" />
      <div className="container mx-auto py-6 px-4 sm:px-0">
        <h1 className="text-gray-700 text-xl sm:text-2xl leading-tight font-bold text-center">
          Oferecemos{" "}
          <Link
            to="/"
            aria-label="Ir a Página Inicial"
            className="text-blue-600"
          >
            serviços
          </Link>{" "}
          dentro e fora da garantia de diversar marcas
        </h1>
      </div>
      <AutorizadasList />
      <SobreNos />
    </Layout>
  )
}

export default Autorizadas
